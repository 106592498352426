.rotate-45 {
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 42px;
}

.header {
    font-size: medium;
    font-weight: bold;
}

.updated-email {
    font-weight: bold;
}